import './index.scss';

document.addEventListener('DOMContentLoaded', () => {
  const mobileMenuBtn = document.querySelector('.main-header__mobile-menu-btn');
  const header = document.querySelector('.main-header');

  if (mobileMenuBtn) {
    mobileMenuBtn.addEventListener('click', () => {
      mobileMenuBtn.classList.toggle('main-header__mobile-menu-btn--active');
      header.classList.toggle('main-header--menu--open');
      document.body.classList.toggle('menu--open');
      document.body.classList.toggle('overflow');
      document.documentElement.classList.toggle('overflow');
    });

    const servicesMenuBtn = document.querySelector('.main-header__submenu__btn');
    const servicesMenu = document.querySelector('.main-header__submenu');

    if (servicesMenuBtn && window.innerWidth <= 1024) {
      servicesMenuBtn.addEventListener('click', () => servicesMenu.classList.toggle('open'));
    }
  }
});

window.addEventListener(
  'scroll',
  () => {
    const header = document.querySelector('.main-header');
    if (window.pageYOffset > 0) {
      header.classList.add('main-header--scrolled');
    } else {
      header.classList.remove('main-header--scrolled');
    }
  },
  false,
);
